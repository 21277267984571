@import './base.scss';

.button {
  // BASIC BUTTON STYLES
  &:link,
  &:visited {
    color: var(--color-purple);
    display: inline-block;
    text-decoration: none;
    padding-bottom: .5rem;
    border-bottom: 1px solid currentColor;
    font-family: 'Inconsolata', monospace;
    font-weight: 600;
    font-size: 2rem;
    transition: all .2s;

  // ANIMATIONS ON HOVER
    &:hover,
    &:active {
      color: var(--color-gray-light-4);
      & .button__text--arrow {
        padding-left: 1rem;
      }
    }
  }

  &__text--arrow {
    color: inherit;
    transition: padding-left .2s;
  }


  // FOR BUTTONS IN HEADER AND PROJECTS SECTION
  &--medium {
    &:link,
    &:visited,
    &:hover,
    &:active {
      font-size: 2rem;
    }
  }

  // FOR BUTTON IN CONTACT SECTION
  &--large {
    &:link,
    &:visited,
    &:hover,
    &:active {
      font-size: 2.2rem;
      letter-spacing: .05em;
    }
  }
  &--large  &__text--arrow {
    display: none;
  }
}
