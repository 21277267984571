@import './base.scss';

.about {
  padding: 0 var(--padding-side);

  &__container {
    display: flex;
    height: 30rem;
    align-items: flex-start;

    @include for-phone-large-landscape-down {
      flex-direction: column;
      align-items: center;
      height: auto;
    }

  }


  &__image-box {
    width: var(--size-about-photo);
    height: var(--size-about-photo);
    border-radius: 5px;
    background-color: var(--color-purple);
    position: relative;
    opacity: 1;


    &::after {
      content: '';
      height: var(--size-about-photo);
      width: var(--size-about-photo);
      border-radius: 5px;
      background: linear-gradient(to right bottom, var(--color-pink), var(--color-purple));
      position: absolute;
      top: 8%;
      left: -10%;
      z-index: -1;
      transition: all .2s;
    }


    &:hover::after {
      top: 5%;
      left: -5%;
    }

  }

  &__image {
    width: var(--size-about-photo);
    height: var(--size-about-photo);
    border-radius: 5px;
    background-image: url(../img/about-lisa-siva-claudia-goetzelmann.png);
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.5;
    transition: all .2s;

  }


  &__image-box:hover &__image {
    opacity: 1;
  }

  &__content {
    width: 60%;
    margin-left: auto;
    font-size: 1.8rem;
    color: var(--color-gray-light-4);
    letter-spacing: .03em;
    line-height: 1.8em;

    @include for-phone-large-landscape-down  {
      margin-left: 0;
      margin-top: 8rem;
      width: 90%;
    }
  }

  &__bio--highlighted {
    color: var(--color-purple);
    font-weight: 600;
  }

  &__interests {
    font-weight: 600;
    margin-top: 4rem;
    margin-bottom: -2rem;

    @include for-phone-large-landscape-down {
      margin-top: 6rem;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;

    & > * {
      margin: 2rem 2rem 0 0;
    }
  }

  &__tag {
    display: flex;
    align-items: center;
    background-color: var(--color-gray-dark-2);
    padding: .5rem 1.5rem;
    border-radius: 30px;
  }

}

.about__tag--0 .tag__icon {
  background: url(../img/icon-swords.svg);
  background-size: cover;
}

.about__tag--1 .tag__icon {
  background: url(../img/icon-wand.svg);
  background-size: cover;
}

.about__tag--2 .tag__icon {
  background: url(../img/icon-spell-book.svg);
  background-size: cover;
}

.tag {
  &__icon {
    height: 4rem;
    width: 4rem;
    margin-right: 1rem;

    @include for-phone-large-landscape-down  {
      height: 3rem;
      width: 3rem;
      margin-right: .75rem;
    }
  }

  &__title {
    font-weight: 300;
    font-family: 'Inconsolata', monospace;
    color: var(--color-gray-dark-0);
  }


}
